import React from 'react'
import WebriQForm from '@webriq/gatsby-webriq-form'

const SendAMessage = () => {
  return (
    <>
      <WebriQForm
        data-form-id="2241221d-7fac-48f8-9f73-71faa9acc7a1"
        id="message-form-main"
        name="Send a message"
        className="message-form"
        method="POST"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 pl-lg-0 pl-md-0 pl-0 pr-lg-2 pr-md-2 pr-0">
              <label htmlFor="first_name">First Name</label>
              <div className="form-group">
                <input
                  className="w-100"
                  type="text"
                  name="First Name"
                  id="first_name"
                  required
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 pr-lg-0 pr-md-0 pr-0 pl-lg-2 pl-md-2 pl-0 mt-lg-0 mt-md-0 mt-4">
              <label htmlFor="first_name">Last Name</label>
              <div className="form-group">
                <input
                  className="w-100"
                  type="text"
                  name="Last Name"
                  id="last_name"
                  required
                />
              </div>
            </div>
            <div className="col-lg-12 p-0 mt-4">
              <label htmlFor="email_address">Email address</label>
              <div className="form-group">
                <input
                  className="w-100"
                  type="email"
                  name="Email Address"
                  id="email_address"
                  required
                />
              </div>
            </div>
            <div className="col-lg-12 p-0 mt-4">
              <label htmlFor="email_address">How can we support you?</label>
              <div className="form-group">
                <textarea
                  // className="form-control"
                  type="text"
                  name="Send a Message"
                  id="send_message"
                  rows="4"
                  className="w-100"
                  required
                />
              </div>
              <div className="centered-captcha w-100 mb-4">
                <div className="webriq-recaptcha" />
              </div>
              <div className="form-group">
                <button className="btn-default btn-brown" type="submit">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </WebriQForm>
    </>
  )
}

export default SendAMessage
