import React from 'react'

import Swiper from 'react-id-swiper'
import {
  LazyLoadComponent,
  // LazyLoadImage,
} from 'react-lazy-load-image-component'

import 'swiper/css/swiper.css'
import 'react-lazy-load-image-component/src/effects/blur.css'

//images

// import BannerHealthLogo from '../../images/new-homepage/Banner_Health_logo.png'
// import UnityPointHealthLogo from '../../images/new-homepage/unitypoint-health.png'
// import SCHealthLogo from '../../images/new-homepage/scl-health.png'

// icons

import LeftArrowIcon from '../../images/new-homepage/left-arrow.svg'
import RightArrowIcon from '../../images/new-homepage/right-arrow.svg'

const AboutUsSwiper = () => {
  const ReviewSwiperData = [
    {
      testimony: `"I wasn't convinced coaching was for me, but Sandy opened my mind to different skill sets and
      creative problem solving techniques that I now use daily."`,
      position: 'OB / GYN (Denver, Colorado)',
      //   img: UnityPointHealthLogo,
    },
    {
      testimony: `"During my coaching… I set clearer expectations and guided my team to come up with a solution
      which they implemented. Staffing, morale, and patient care have all improved. I learned I don’t
      need to fix things by myself."`,
      position: 'Medical Director (New England)',
    },
    {
      testimony: `"Sandy breaks through physicians' guarded skepticism to unlock their true passion for patient
      care, empathy and leadership."`,
      position: 'Chief Clinical Officer (Broomfield, Colorado)',
    },
    {
      testimony: `"Sandy has a presence that immediately disarms even the most doubtful physician and allows
      for rapid, meaningful growth. I recommend her program without hesitation."`,
      position: 'Director of Provider Services<br />(Des Moines, Iowa)',
    },
    {
      testimony: `"I recommend Sandy without reservation!"`,
      position: 'VP of Human Resources<br />(Grand Junction, Colorado)',
    },
    {
      testimony: `"Sandy gets the physician perspective and helps us all realize our potential as leaders."`,
      position: 'Chief Medical Officer (Scottsdale, Arizona)',
    },
    {
      testimony: `"Coaching with Sandy will be both eye opening, and life changing, for any physician."`,
      position: 'General Surgeon (Bozeman, Montana)',
    },
  ]

  const articleCarousel = {
    loop: true,
    spaceBetween: 10,
    speed: 1000,
    autoplay: {
      delay: 5000,
    },
    // autoplay: false,
    disableOnInteraction: false,
    navigation: {
      nextEl: '#article-next',
      prevEl: '#article-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      dynamicBullets: true,
      clickable: true,
    },
    fadeEffect: {
      crossFade: true,
    },
    breakpoints: {
      1366: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      991.98: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 1,
      },
      767: {
        slidesPerView: 1,
      },
    },
  }
  return (
    <>
      <div className="testimonial-swiper">
        <div class="fullwidth-nav">
          <div class="fullwidth-nav-prev" id="article-prev">
            <img src={LeftArrowIcon} alt="Left Arrow" />
          </div>
          <div class="fullwidth-nav-next" id="article-next">
            <img src={RightArrowIcon} alt="Right Arrow" />
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 px-lg-4 px-md-4">
              <LazyLoadComponent>
                <Swiper {...articleCarousel}>
                  {ReviewSwiperData.map(review => (
                    <div className="h-auto p-1">
                      <div
                        className="container h-100 testimonial-container p-lg-4 p-md-5 p-4"
                        style={{ boxShadow: '0px 3px 14px rgb(0 0 0 / 10%)' }}
                      >
                        <div className="row h-100">
                          <div className="col-lg-12 align-self-center">
                            <p>
                              {/* "Sandy was the driving force behind a highly
                              successful leadership program at a large
                              integrated healthcare delivery system, where she
                              transformed physician leadership training." */}
                              {review.testimony}
                            </p>
                            {/* <span
                              style={{ color: '#975747', fontFamily: 'Rubik' }}
                            >
                              {review.position}
                            </span> */}
                            <div
                              style={{ color: '#975747', fontFamily: 'Rubik' }}
                              dangerouslySetInnerHTML={{
                                __html: review.position,
                              }}
                            />
                          </div>
                          {/* <LazyLoadImage
                              alt="Testimonial Img"
                              className="img-fluid"
                              height="100%"
                              width="100%"
                              src={review.img}
                            /> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </Swiper>
              </LazyLoadComponent>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutUsSwiper
