import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import SEO from '../components/seo'

//swiper
// import TestimonialSwiper from '../components/swiper/testimonial-swiper'
import AboutUsSwiper from '../components/swiper/about-us-swiper'

//images
import TheSparkImg from '../images/new-homepage/about-us/the-spark.png'
import BecomingACoachImg from '../images/new-homepage/about-us/becoming-coach.png'
import PartneringImg from '../images/new-homepage/about-us/Partnering.png'
import aboutcredentialbcc from '../images/aboutsandy-credential-bcc-min.png'
import aboutcredentialfache from '../images/aboutsandy-credential-fache-min.png'
import aboutcredentialpccicf from '../images/aboutsandy-credential-pccicf-min.png'
import aboutcredentialpci from '../images/aboutsandy-credential-pci-min.png'
import PhysicianCoachedImg from '../images/new-homepage/about-us/500+physicians.png'
import TransformationImg from '../images/new-homepage/about-us/transformation-for-all.png'
import bhlogograyscaled from '../images/new-homepage/about-us/banner-health-grayscaled.png'
import vwhlogograyscaled from '../images/new-homepage/about-us/valleywise-health-grayscale.png'
import cmhlogograyscaled from '../images/new-homepage/about-us/cmh_grayscaled.png'
import sclhlogograyscaled from '../images/new-homepage/about-us/scl-grayscaled.png'
import upclogograyscaled from '../images/new-homepage/about-us/unity-grayscaled.png'
import thpglogograyscaled from '../images/new-homepage/about-us/thpglogo-grayscaled.png'
import UsImg from '../images/new-homepage/us-edited.png'

import TransformBg from '../images/new-homepage/about-us/transformation-bg-v3.jpg'

import TourLine1 from '../images/new-homepage/about-us/tour-line-1.svg'
import TourLine2 from '../images/new-homepage/about-us/tour-line-2.svg'
import TourLine3 from '../images/new-homepage/about-us/tour-line-3.svg'

//icons
import LinkedInIcon from '../images/new-homepage/about-us/linkedin.svg'

//forms
import SendAMessage from '../components/forms/send-a-message'

const AboutUsNew = ({ location, data }) => {
  const siteTitle = 'About Us'
  const siteDescription = data.site.siteMetadata.description

  return (
    <>
      <Layout location={location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <div className="about-us-wrapper">
          <section
            className="about-hero-wrapper"
            style={{ padding: '200px 0', marginTop: '5rem' }}
          >
            <article>
              <div className="container">
                <div className="row">
                  <div className="col-lg-10 col-md-9 text-lg-center ml-md-0 hero-text-wrapper mx-lg-auto">
                    <h1 className="ml-4 black-brightness">
                      Let's transform healthcare,
                      <br />
                      by bringing all of ourselves
                      <br />
                      to the table
                    </h1>
                  </div>
                </div>
              </div>
            </article>
          </section>
          <section
            className="content-wrapper"
            style={{ padding: '5rem 0', background: '#fff' }}
          >
            <article className="spark-section">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-12 text-center">
                    <LazyLoadImage
                      alt="The spark"
                      // style={{ width: '3rem', height: '3rem' }}
                      className="img-fluid"
                      src={TheSparkImg}
                    />
                  </div>
                  <div className="col-lg-6 col-md-10 align-self-center mx-lg-0 mx-md-auto">
                    <h2>The spark</h2>
                    <p>
                      Growing up, I felt most alive when I was helping other
                      people shine.
                    </p>
                    <p>
                      From organizing Family Reunion Olympics to a Neighborhood
                      Circus --
                      <b className="ml-2">
                        helping people experience meaning in new ways was easy
                        and fed my soul.
                      </b>
                    </p>
                  </div>
                </div>
              </div>
            </article>

            <div
              className="mx-auto text-center container d-lg-block d-md-none d-none"
              style={{ marginTop: '-4rem' }}
            >
              <LazyLoadImage
                alt="Tour Line"
                className="img-fluid"
                width="50%"
                src={TourLine1}
              />
            </div>

            <article>
              <div className="container">
                <div
                  className="row flex-lg-row flex-md-column-reverse flex-column-reverse row-with-neg-margin"
                  style={{ marginTop: '-4rem' }}
                >
                  <div className="col-lg-6 col-md-10 align-self-center mx-lg-0 mx-md-auto">
                    <h2>Becoming a coach</h2>
                    <p>
                      My world was rocked when my son was diagnosed with severe
                      disabilities and given less than a year to live. But
                      through a series of "God winks", I enrolled in a coach
                      training program and began to reframe this tragedy as an
                      opportunity. I began to ask my kids:{' '}
                      <b className="mr-1">
                        "<i>What's most important for us as a family today?</i>"
                      </b>
                      and
                      <b className="ml-1">
                        <i>"How can we do more of that?"</i>
                      </b>
                    </p>
                    <p className="font-weight-bold my-4">
                      I learned that we each have the opportunity to feel deep
                      meaning in our lives, but we rarely get there alone.
                    </p>
                    <Link to="/book" className="btn-default btn-purple">
                      Download Sandy's Book: Lessons from Zachary
                    </Link>
                  </div>
                  <div className="col-lg-6 col-md-12 text-center mt-lg-0 mt-md-5 mt-4">
                    <LazyLoadImage
                      alt="Becoming a coach"
                      // style={{ width: '3rem', height: '3rem' }}
                      className="img-fluid"
                      src={BecomingACoachImg}
                    />
                  </div>
                </div>
              </div>
            </article>

            <div
              className="mx-auto text-center container d-lg-block d-md-none d-none"
              style={{ marginTop: '-4rem' }}
            >
              <LazyLoadImage
                alt="Tour Line"
                className="img-fluid"
                width="50%"
                src={TourLine2}
              />
            </div>

            <article>
              <div className="container">
                <div
                  className="row row-with-neg-margin"
                  style={{ marginTop: '-4rem' }}
                >
                  <div className="col-lg-6 col-md-12 text-center mt-lg-0 mt-md-5 mt-4">
                    <LazyLoadImage
                      alt="Partnering with physicians"
                      className="img-fluid"
                      src={PartneringImg}
                    />
                  </div>
                  <div className="col-lg-6 col-md-10 align-self-center mx-lg-0 mx-md-auto">
                    <h2>Partnering with physicians</h2>
                    <p>
                      We had appointments with a lot of physicians, and every
                      once in a while -- they gave us hope by seeing my son as a
                      person, not just a disability. The more physicians I
                      talked to, the more I heard about their yearnings to serve
                      and heal.
                    </p>
                    <p>
                      So I made one of the best decisions of my life, and
                      <b className="ml-1">
                        started coaching physicians so they could thrive and
                        touch more patients' lives, like they did ours.
                      </b>
                    </p>
                    <div className="btn-wrapper w-50 mt-4">
                      <a
                        href="https://www.linkedin.com/in/sandy-scott-fache"
                        rel="noopener noreferrer"
                        target="_blank"
                        className="btn-default btn-brown text-capitalize d-flex align-item-center justify-content-center"
                      >
                        <LazyLoadImage
                          height="25px"
                          width="25px"
                          src={LinkedInIcon}
                          alt="Linkedin Icon"
                          className="mr-2"
                        />
                        Connect on LinkedIn
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </article>

            <div
              className="mx-auto text-center container d-lg-block d-md-none d-none"
              style={{ marginTop: '-4rem' }}
            >
              <LazyLoadImage
                alt="The spark"
                className="img-fluid"
                width="50%"
                src={TourLine3}
              />
            </div>

            <article
              className="mt-lg-0 mt-md-5 mt-5"
              style={{
                background: `url(${TransformBg})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                //paddingBottom: '17rem',
              }}
            >
              <div className="container">
                <div
                  className="row flex-lg-row flex-md-column-reverse flex-column-reverse row-with-neg-margin"
                  style={{ marginTop: '-6rem' }}
                >
                  <div
                    className="col-lg-6 align-self-center"
                    style={{ paddingTop: '6rem', paddingBottom: '11rem' }}
                  >
                    <h2>Transformation for all</h2>
                    <p>
                      After becoming a Top 1% Physician Coach and launching
                      physician development programs at several health systems,
                      <b className="mx-1">
                        we're focused on finding new ways to unleash the human
                        potential of physicians across the world.
                      </b>
                      It's my way of giving back to the people who gave me 28
                      more years with my son then I was supposed to have -- and
                      I'll be doing it for as long as humanly possible.
                    </p>
                    <div className="container p-0">
                      <div className="row">
                        <div className="col-lg col-md-4 col">
                          <LazyLoadImage
                            // style={{ width: '3rem', height: '3rem' }}
                            src={aboutcredentialbcc}
                            alt="Board Certified Coach"
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-lg col-md-4 col-6">
                          <LazyLoadImage
                            // style={{ width: '3rem', height: '3rem' }}
                            src={aboutcredentialpccicf}
                            alt="Professional Certified Coach"
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-lg col-md-4 col-6">
                          <LazyLoadImage
                            // style={{ width: '3rem', height: '3rem' }}
                            src={aboutcredentialpci}
                            alt="Physician Coaching Institute"
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-lg col-md-4 col-6">
                          <LazyLoadImage
                            // style={{ width: '3rem', height: '3rem' }}
                            src={aboutcredentialfache}
                            alt="Fache"
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-lg col-md-4 col-6">
                          <LazyLoadImage
                            // style={{ width: '3rem', height: '3rem' }}
                            src={PhysicianCoachedImg}
                            alt="Phyician Coached"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <Link
                      to="/request"
                      className="btn-default btn-purple text-capitalize mt-4 mr-lg-0 mr-md-3 mr-0"
                    >
                      Request a Free Coaching Session
                    </Link>
                    <Link
                      to="/groups"
                      className="btn-default btn-purple text-capitalize mt-4"
                    >
                      Request Group Information
                    </Link>
                  </div>
                  <div className="col-lg-6 col-md-12 text-center mt-lg-0 mt-md-5 mt-4">
                    <LazyLoadImage
                      style={{ paddingTop: '3.3rem'}}
                      // style={{ width: '3rem', height: '3rem' }}
                      src={TransformationImg}
                      alt="Transformation for all"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </article>
          </section>
          <section className="review-wrapper">
            <article>
              <div className="container main-container">
                <div className="partner-box">
                  <div className="row">
                    <div className="col-lg-12 mx-lg-auto mx-md-auto mx-auto text-lg-center text-md-center text-center">
                      <h2>
                        Whatever your challenge,
                        <br />
                        we're here to serve
                      </h2>
                    </div>
                    {/* <div className="col-lg-10 mx-lg-auto mx-md-auto mx-auto text-lg-center text-md-center text-center">
                        <p className="py-4">
                          We're here to help organizations develop their
                          physician leadership pipeline, because it's our best
                          shot at making healthcare work better for patients.
                          And we bring together the best strategies, tools and
                          experience to help you do just that.
                        </p>
                      </div> */}
                    <div className="col-lg-10 mx-auto px-lg-auto px-md-auto px-0">
                      <AboutUsSwiper />
                    </div>
                    {/* <div className="col-lg-12 text-center">
                        <Link
                          to="/"
                          className="btn-default btn-purple text-capitalize"
                        >
                          Group Programs
                        </Link>
                      </div> */}
                  </div>
                  <div className="row grayscaled-logo px-lg-5 mx-lg-5 px-md-5 mx-md-auto">
                    <div className="col-lg-4 col-md-6 col-6">
                      <LazyLoadImage
                        alt="Banner Health"
                        className="img-fluid"
                        src={bhlogograyscaled}
                      />
                    </div>
                    <div className="col-lg-4 col-md-6 col-6">
                      <LazyLoadImage
                        alt="Valleywise Health"
                        className="img-fluid"
                        src={vwhlogograyscaled}
                      />
                    </div>
                    <div className="col-lg-4 col-md-6 col-6">
                      <LazyLoadImage
                        alt="Central Maine Healthcare"
                        className="img-fluid"
                        src={cmhlogograyscaled}
                      />
                    </div>
                    <div className="col-lg-4 col-md-6 col-6">
                      <LazyLoadImage
                        alt="SCL Health"
                        className="img-fluid"
                        src={sclhlogograyscaled}
                      />
                    </div>
                    <div className="col-lg-4 col-md-6 col-6">
                      <LazyLoadImage
                        alt="Unity Point Clinic"
                        className="img-fluid"
                        src={upclogograyscaled}
                      />
                    </div>
                    {/* <div className="col-lg-4 col-md-6 col-6">
                      <LazyLoadImage
                        alt="Texas Health Physicians Group"
                        className="img-fluid"
                        src={thpglogograyscaled}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </article>
          </section>
          <section
            className="send-a-message-wrapper"
            style={{ background: '#E4D5C9', padding: '100px 0' }}
          >
            <article>
              <div className="container">
                <div className="row">
                  <div className="col-lg-10 mx-auto text-center">
                    <h2>
                      Want to achieve that secret dream?
                      <br />
                      Send us a message.
                    </h2>
                  </div>
                </div>
                <div className="row my-5">
                  <div className="col-lg-6 ml-lg-auto">
                    <LazyLoadImage
                      alt="Us"
                      className="img-fluid"
                      height="100%"
                      width="100%"
                      src={UsImg}
                    />
                  </div>
                  <div className="col-lg-6 col-md-10 mr-lg-auto mx-lg-0 mx-md-auto mt-lg-0 mt-md-5 mt-5">
                    <SendAMessage />
                  </div>
                </div>
              </div>
            </article>
          </section>
        </div>
      </Layout>
    </>
  )
}

export default AboutUsNew

export const aboutPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
